const state = () => ({
    loadingTermos: false,
    showModelTermos: false,
    showModelAlert: true
})
  
const mutations = {
    SHOW_MODAL_TERMOS: (state, payload) => {
        state.showModelTermos = payload
    },
    SHOW_MODAL_ALERT: (state, payload) => {
        state.showModelAlert = payload
    }
}

const actions = {
}

const getters = {
    getShowModalTermos: state => state.showModelTermos,
    getShowModalAlert: state => state.showModelAlert,
    getLoadingTermos: state => state.loadingTermos
}

export default {
    state,
    getters,
    mutations,
    actions
};