import SocioService from '../../services/SocioService'

const state = () => ({
    dadosComercial: {
        clinica: '',
        telefone_1: '',
        telefone_2: '',
        telefone_wp: '',
        rqe: null,
        zip: '',
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        country: '',
        check_divulgation: false,
        especialista: false,
        termosCondic: false
    },
    loadingComercial: false,
})
  
const mutations = {
    SET_DADOS_COMERCIAL: (state, payload) => {
        state.dadosComercial = payload;
    },
    SET_LOADING_COMERCIAL: (state, payload) => {
        state.loadingComercial = payload
    },
}

const actions = {
    async setDadosComerciais({commit, state, rootState}){
        try {
            commit('SET_LOADING_COMERCIAL', true)
            const formData = new FormData();
            formData.append('firstName', rootState.user.user.firstName);
            formData.append('lastName', rootState.user.user.lastName);
            formData.append('rqe', state.dadosComercial.rqe);
            formData.append('dadosFormularios', JSON.stringify(state.dadosComercial));

            const response = await SocioService.setDadosComerciais(formData);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_COMERCIAL', false)
        } catch (error) {
            commit('SET_LOADING_COMERCIAL', false)
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getDadosComercial: state => state.dadosComercial,
    getLoadingComercial: state => state.loadingComercial
}

export default {
    state,
    getters,
    mutations,
    actions
};