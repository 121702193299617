// cryptoModule.js
import CryptoJS from 'crypto-js';

const secretKey = '6g3wqaf!564f&5456Fgg';


const encrypt = (data) => {
    const jsonString = JSON.stringify(data);

    return CryptoJS.AES.encrypt(jsonString, secretKey).toString();
}


const decrypt = (encryptedData) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const response = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8))

    return response;
};

export { encrypt, decrypt };