import CategoryService from '../../services/CategoryService'

const state = () => ({
    category: null,
    categories: localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : [],
    postsLoading: false
})
  
const mutations = {
    SET_CATEGORY: (state, payload) => {
        state.category = payload;
    },
    SET_CATEGORIES: (state, payload) => {
        state.categories = payload.categories;
        localStorage.setItem('categories', JSON.stringify(payload));
    },
    SET_CATEGORIES_LOADING: (state, payload) => {
        state.categoriesLoading = payload;
    }
}

const actions = {
    async fetchCategories({commit}){
        try {
            commit('SET_CATEGORIES_LOADING', true);
            const response = await CategoryService.get();
            commit('SET_CATEGORIES', response.data);
            commit('SET_CATEGORIES_LOADING', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCategory({commit}, slug){
        try {
            commit('SET_CATEGORIES_LOADING', true);
            const response = await CategoryService.getOne(slug);
            commit('SET_CATEGORY', response.data);
            commit('SET_CATEGORIES_LOADING', false);
        } catch (error) {
            commit('SET_CATEGORIES_LOADING', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getCategory: state => state.category,
    gettCategoies: state => state.categories,
    gettCategoiesLoading: state => state.categoriesLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};