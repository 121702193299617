import Api from './Api'

export default {
    login(credentials) {
        return Api().post('auth/login-sbn', credentials);
    },
    associarUser(credentials) {
        return Api().post('users/associarAPI', credentials);
    },
    login_API_SBU(credentials) {
        const headers = {
            'Authorization': `Bearer ${process.env.VUE_APP_API_SBU_SP}` // Substitua pelo seu token de autenticação
        };
        return Api().post('https://siasbu.org.br/unimagem-service/authentication', credentials, { headers });
    },
    getUser(){
        return Api().get('users/profile');
    },
    getDataUser(){
        return Api().get('users/all_data');
    },
    create(formData){
        return Api().post('users/register', formData);
    },
    newPassVerifyEmail(userInput){
        return Api().post(`auth/newPassword/verify-user`, userInput );
    },
    newPassVerifyToken(token){
        return Api().post(`auth/newPassword/verify-token`, token);
    },
    newPass(credentials){
        return Api().post(`auth/newPassword`, credentials );
    },
    updateAddress(credentials){
        return Api().put('users/update_address', credentials);
    },
    updatePhones(phones){
        return Api().put('users/update_phones', phones);
    },
    updateProfileImage(image){
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return Api().post(`users/upload-image`, { image }, { headers });
    }
}