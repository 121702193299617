import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/PostsView.vue')
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/register/:sociocategoria?',
    name: 'Cadastro',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/profile/settings',
    name: 'Settings',
    component: () => import('@/views/SettingsView.vue')
  },
  {
    path: '/profile/account',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/post/:slug',
    name: 'Post',
    component: () => import('@/views/PostView.vue'),
  },
  {
    path: '/category/:slug?',
    name: 'Posts',
    component: () => import('@/views/PostsCategoryView.vue'),
  },
  {
    path: '/password-reset/:tokenPass?',
    name: 'Reset Password',
    component: () => import('@/views/PasswordResetView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0) // Isso fará a rolagem para o topo da página
  next()
})

export default router
