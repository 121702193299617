import NavBarService from '../../services/NavBarService'

const state = () => ({
    navbar: (() => {
        try {
            return {
                timestamp: localStorage.getItem('navbar') ? JSON.parse(localStorage.getItem('navbar')).timestamp : null,
                data: localStorage.getItem('navbar') ? JSON.parse(localStorage.getItem('navbar')).data : [],
            }
        } catch (error) {
            localStorage.removeItem('navbar');
            return [];
        }
    })(),
    navbarLoading: false
})
  
const mutations = {
    SET_NAVBAR: (state, payload) => {
        state.navbar.data = payload;
        state.navbar.timestamp = new Date().getTime() + (1 * 24 * 60 * 60 * 1000); // (24 horas * 60 minutos * 60 segundos * 1000 milissegundos) ao timestamp
        localStorage.setItem('navbar', JSON.stringify(state.navbar));
    },
    SET_NAVBAR_LOADING: (state, payload) => {
        state.navbarLoading = payload;
    }
}

const actions = {
    async fetchNavbar({commit}){
        try {
            commit('SET_NAVBAR_LOADING', true);
            const response = await NavBarService.get();
            commit('SET_NAVBAR', response.data);
            commit('SET_NAVBAR_LOADING', false);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getNavBar: state => {
        const isExpired = state.navbar.timestamp && state.navbar.timestamp < new Date().getTime();
        return isExpired || !state.navbar.data ? [] : state.navbar.data;
    },
    getnavbarLoading: state => state.navbarLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};