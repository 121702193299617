import PostsService from '../../services/PostsService'

const state = () => ({
    post: null,
    posts: localStorage.getItem('posts') ? JSON.parse(localStorage.getItem('posts')) : [],
    postsLoading: false,
    total: 0,
    pages: 0,
    page: 1
})
  
const mutations = {
    SET_POST: (state, payload) => {
        state.post = payload;
    },
    SET_POSTS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.posts = payload.posts
        }else{
            state.posts = [...state.posts, ...payload.posts]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_POSTS_LOADING: (state, payload) => {
        state.postsLoading = payload;
    }
}

const actions = {
    async fetchPosts({commit}, { keyword = '', pageNumber = 1, sortBy = 'recentes', category = ''}){
        try {
            commit('SET_POSTS_LOADING', true);
            const response = await PostsService.get(keyword, pageNumber, sortBy, category);
            commit('SET_POSTS', response.data);
            commit('SET_POSTS_LOADING', false);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPost({commit}, slug){
        try {
            commit('SET_POSTS_LOADING', true);
            const response = await PostsService.getOne(slug);
            commit('SET_POST', response.data);
            commit('SET_POSTS_LOADING', false);
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPost: state => state.post,
    getPosts: state => state.posts,
    getPostsLoading: state => state.postsLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};